<template>
  <div class="content">
    <div class="topo">
      <!-- <div class="button">
        <button type="button" class="btn btn_secondary ripple" @click="logout()">
          <i class="icone material-icons">
            power_settings_new
          </i>
        </button>
      </div> -->

      <div class="avatar" :style="{ backgroundColor: hslColor }">
        {{ initialsName }}
      </div>

      <div class="dados">
        <div class="name">
          {{ currentUser.name }}
        </div>
        <div class="email">
          {{ currentUser.email }}
        </div>
      </div>

      <div class="oval">
        <div class="stripes" />
      </div>
    </div>

    <!-- Painel // Configurações -->
    <div class="panel first">
      <div class="title">
        <div class="text">
          <!-- <i class="icone material-icons">
            settings
          </i> -->
          Configurações
        </div>
      </div>

      <!-- Itens de navegação -->
      <div class="item">
        <button type="button" class="btn" @click="goToPage('MarketPreferences')">
          <i class="icone material-icons">
            pin_drop
          </i>
          Praças
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
      </div>

      <div class="item">
        <button type="button" class="btn" @click="goToPage('CustomProgramsMarketsPreferences')">
          <i class="icone material-icons">
            pin_drop
          </i>
          Praças Personalizadas
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
      </div>

      <div class="item">
        <button type="button" class="btn" @click="goToPage('TvNetworkPreferences')">
          <i class="icone material-icons">
            tv
          </i>
          Emissoras
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
      </div>

      <div v-if="isApp" class="item">
        <button type="button" class="btn" @click="goToPage('Notifications')">
          <i class="icone material-icons">
            notifications
          </i>
          Notificações
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
      </div>

      <!-- <div class="item">
        <button type="button" class="btn" @click="goToPage('KpiPreferences')">
          <i class="icone material-icons">
            settings_applications
          </i>
          Personalização
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
      </div> -->
    </div>

    <!-- Painel // Versão -->
    <div class="panel">
      <div class="title">
        <div class="text">
          <!-- <i class="icone material-icons">
            developer_mode
          </i> -->
          Sobre o App
        </div>
      </div>

      <!-- Itens de navegação -->
      <div class="item">
        <button type="button" class="btn">
          <i class="icone material-icons">
            update
          </i>
          Versão

          <span>
            {{ version }}
          </span>
        </button>
      </div>

      <div class="item">
        <button type="button" class="btn" @click="showTutorial()">
          <i class="icone material-icons">
            assistant
          </i>
          Visualizar tutorial
          <i class="icone material-icons">
            open_in_new
          </i>
        </button>
      </div>

      <div class="item">
        <button type="button" class="btn" @click="logout()">
          <i class="icone material-icons">
            power_settings_new
          </i>
          Sair do APP
          <i class="icone material-icons">
            open_in_new
          </i>
        </button>
      </div>
    </div>

    <!-- <div class="slice">
      <button type="button" class="btn btn_full btn_secondary ripple" @click="goToPage('Home')">
        Voltar
      </button>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import initials from 'initials';
import device from '@/utils/device';

export default {
  data() {
    return {
      version: '2.27.3',
      isApp: device.isApp(),
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    initialsName() {
      const { name, email } = this.currentUser;
      return initials(name ? name : email).toUpperCase().substr(0, 2);
    },
    hslColor() {
      const { name } = this.currentUser;
      return this.stringToHslColor(name, 100, 20);
    },
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'Home' });
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h},${s}%,${l}%)`;
    },
    showTutorial() {
      this.$store.dispatch('selectIsTipsTourDone', false);
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

// SMARTPHONES
@media (max-width: 600px) {

  .content {
    position: relative;
    z-index: 999;
    display: block;
    width: 100vw;
    height: 100vh;
    padding: 0 0 80px 0;
    overflow-y: scroll;
    background-color: $color-gray-back;
    animation: fadeIn 0.8s ease-in-out forwards;

    // Topo
    .topo {
      position: fixed;
      top: env(safe-area-inset-top, 0);
      left: 0;
      display: flex;
      width: 100%;
      height: 180px;
      padding-bottom: 40px;
      // background: linear-gradient(140deg, $color-primary, $color-secondary);
      // background-size: 100% 100%;
      background-color: $color-gray-dark;
      box-shadow: 0 4px 8px rgba($color-gray-darker, 0.32);

      // Botão
      .button {
        position: absolute;
        z-index: 3;
        top: 72px;
        right: 50%;
        margin-right: -80px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        box-shadow: 0 2px 8px rgba($color-gray-dark, 0.24);

        .btn {
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 100%;
          align-items: center;
          border: none;

          i {
            font-size: 1.4em;
            padding: 0;
            margin: 4px 0 0 0;
            color: $color-secondary;
          }
        }
      }

      // Avatar
      .avatar {
        position: absolute;
        z-index: 2;
        top: 56px;
        left: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-left: -32px;
        width: 64px;
        height: 64px;
        background: $color-gray-lighter;
        border-radius: 100%;
        box-shadow: 0 8px 16px rgba($color-gray-darker, 0.8);
        font-family: $primary-typo;
        color: $color-gray-lighter;
      }

      // Content
      .dados {
        position: absolute;
        top: 132px;
        width: 100%;
        text-align: center;

        .name {
          font-family: $secondary-typo;
          font-size: 0.8em;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.16em;
          color: $color-gray-lighter;
        }

        .email {
          font-family: $secondary-typo;
          font-size: 0.8em;
          font-style: italic;
          text-transform: lowercase;
          color: rgba($color-gray-light, 0.8);
          margin: 0;
        }
      }

      .oval {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -100vw;
        width: 200vw;
        height: 92px;
        border-radius: 100%;
        // background-color: $color-secondary-dark;
        background-color: $color-gray-darker;
        box-shadow: 0 8px 24px rgba($color-gray-darker, 0.88);

        .stripes {
          position: relative;
          width: 64%;
          height: 98%;
          // background: repeating-linear-gradient(
          //   45deg,
          //   rgba($color-secondary, 0.4),
          //   rgba($color-secondary, 0.4) 2px,
          //   rgba($color-secondary, 0) 2px,
          //   rgba($color-secondary, 0) 10px
          // );
          background: repeating-linear-gradient(
            48deg,
            rgba($color-gray-dark, 0.4),
            rgba($color-gray-dark, 0.4) 4px,
            rgba($color-gray-dark, 0) 4px,
            rgba($color-gray-dark, 0) 10px
          );
          background-size: contain;
        }
      }
    }

    // Panel
    .panel {
      display: flex;
      flex-wrap: wrap;
      width: 92%;
      box-sizing: border-box;
      padding: 0 0 8px 0;
      margin: 16px auto;
      border-radius: 4px;
      background-color: $color-gray-lighter;

      &.first {
        margin: 216px auto 0 auto;
      }

      .title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 8px 0;
        padding: 16px;
        border-bottom: solid 1px rgba($color-gray-light, 0.4);

        .text {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-family: $primary-typo;
          font-size: 0.72em;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          color: $color-gray-dark;

          i {
            font-size: 1.4em;
            margin: 0 8px 0 0;
            color: $color-gray-light;
          }
        }
      }

      // Itens
      .item {
        width: 100%;
        padding: 0 8px;

        .btn {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 40px;
          box-shadow: none;
          border-radius: 4px;
          font-size: 0.72em;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0.08em;
          color: $color-gray-main;
          background-color: $color-gray-lighter;

            i {
              font-size: 1.4em;
              margin: 0 16px 0 0;
              color: $color-gray-light;
              transition: color 0.56s ease-in-out;

              &:last-child {
                margin: 0 0 0 auto;
                font-size: 1.6em;
                color: $color-gray-main;
              }
            }

            span {
              margin: 0 0 0 auto;
              font-size: 1em;
              font-weight: 600;
              font-style: normal;
              color: $color-gray-main;
            }

        }
      }
    }

    // Botão Full
    .slice {
      width: 100%;
      margin: 0;
      padding: 16px 0;

      .btn_primary {
        border-radius: 4px;
        box-shadow: none;
        width: 100%;

          // Ripple Effect
          &.ripple {
            background-position: center;
            background: $color-gray-back;
            border: solid 1px rgba($color-secondary, 0.4);
            color: $color-secondary;
            transition: background 0.56s ease-in-out, opacity 0.56s ease-in-out;

            i {
              font-size: 1.4em;
              margin: 0 8px 0 0;
              color: $color-gray-light;
              transition: color 0.56s ease-in-out;

              &:last-child {
                margin-left: auto;
                font-size: 1.6em;
                color: $color-gray-main;
              }
            }

            &:hover {
              background: $color-secondary radial-gradient(circle, transparent 1%, $color-secondary 1%) center/15000%;
              color: $color-gray-lighter;

              & i {
                color: $color-secondary;
              }
            }

            &:active {
              background-color: rgba($color-secondary, 0.8);
              background-size: 100%;
              transition: background 0s;
            }
          }
      }
    }

    .slice {
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 56px;
        background-color: $color-gray-lighter;

        .btn {
            border: none;
            margin: -16px 0 0 0;

            &:hover {
              border: none;
            }
        }
    }
    // ---------------

  }
}

</style>
